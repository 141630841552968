import React from 'react';
import {connect} from 'react-redux';
import {signInAction} from '../store/actions/authaction';
import {Redirect} from 'react-router-dom';

class SignIn extends React.Component {
    state = {
      email: '',
      password: ''
    }
    handleChange = (e) => {
      this.setState({
        [e.target.id]: e.target.value
      })
    }
    handleSubmit = (e) => {
      e.preventDefault();
      this.props.signInUser(this.state);
    }
    render() {
      const{auth,authError}=this.props;
      if(auth.uid) return <Redirect to='/whatsappform'/>
      return (
        <div className="container ">
          <form className="white " style={{padding:'20px',margin:'80px 0px',boxShadow:'0px 0px 5px lightgrey'}} onSubmit={this.handleSubmit}>
            <h5 className="grey-text text-darken-3 center ">Sign In</h5>
            <br/>
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input type="email" id='email' onChange={this.handleChange} />
            </div>
            <div className="input-field">
              <label htmlFor="password">Password</label>
              <input type="password" id='password' onChange={this.handleChange} />
            </div>
            <div className="input-field">
              <button className="btn pink lighten-1 z-depth-0">Login</button>
            </div>
            <div className="center red-text">
            {authError?<p>{authError}</p>:null}
            </div>
            
          </form>
        </div>
      )
    }
  }
  const mapStateToProps=(state)=>{
    return{
      authError:state.auth.authError,
      auth:state.firebase.auth
    }
  }
  const mapDispatchToProps=(dispatch)=>{
    return{
    signInUser:(userCredentials)=>dispatch(signInAction(userCredentials))
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(SignIn)