import React from "react";
import { Collapsible, CollapsibleItem, Icon, Table } from "react-materialize";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import moment from 'moment'

class contactForm extends React.Component {
  render() {
    const { contactforms, auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;
    return (
      <div
        className="container contactform"
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          boxShadow: "0px 0px 5px lightblue",
        }}
      >
        {contactforms && (
          <p className="center submittedforms">
            Number of Contact Forms Submitted-{contactforms.length}
          </p>
        )}
        <Collapsible accordion popout style={{ fontWeight: 600 }}>
          {contactforms &&
            contactforms.map((contactform) => {
              return (
                <CollapsibleItem
                  expanded={false}
                  header={contactform.email}
                  node="div"
                  icon={
                    <Icon right className="right">
                      arrow_drop_down_circle
                    </Icon>
                  }
                  key={contactform.id}
                >
                  <div className="container">
                  <p style={{ fontWeight: 500 }}>
                    <span style={{ fontWeight: 600, color: "darkblue" }}>
                      NAME : {""}
                    </span>
                    {contactform.name}
                  </p>
                  <p style={{ fontWeight: 500 }}>
                    <span style={{ fontWeight: 600, color: "darkblue" }}>
                      QUESTION : {" "}
                    </span>
                    {contactform.question}
                  </p>
                  <p style={{ fontWeight: 500 }}>
                    <span style={{ fontWeight: 600, color: "darkblue" }}>
                      Submitted On : {" "}
                    </span>
                    {moment(contactform.createdAt.toDate()).format('LLL')}
                  </p>
                  </div>
                </CollapsibleItem>
              );
            })}
        </Collapsible>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    contactforms: state.firestore.ordered.contactForm,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "contactForm",orderBy: ['createdAt', 'desc'] }])
)(contactForm);
