import React from "react";
import { Collapsible, CollapsibleItem, Icon, Table } from "react-materialize";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import moment from "moment";

class ApplyForm extends React.Component {
  render() {
    const { applyforms, auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;
    return (
      <div
        className="container contactform"
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          boxShadow: "0px 0px 5px lightblue",
        }}
      >
        {applyforms && (
          <p className="center submittedforms">
            Number of Application Forms Submitted-{applyforms.length}
          </p>
        )}
        <Collapsible accordion popout style={{ fontWeight: 600 }}>
          {applyforms &&
            applyforms.map((applyform) => {
              return (
                <CollapsibleItem
                  expanded={false}
                  header={applyform.studentName}
                  node="div"
                  icon={
                    <Icon right className="right">
                      arrow_drop_down_circle
                    </Icon>
                  }
                  key={applyform}
                >
                  <div className="container">
                    <Table>
                      <tbody>
                        <tr>
                          <th style={{ color: "darkblue" }}>PARENT NAME</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {applyform.parentName}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>CONTACT NUMBER</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {applyform.mobileNumber}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>WHATSAPP NUMBER</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {applyform.whatsappNumber}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>STATE</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {applyform.state}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>CITY</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {applyform.city}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>NEET SCORE</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {applyform.neetScore}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>HSC PERCENTAGE</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {applyform.hscPercentage}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>PCB PERCENTAGE</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {applyform.pcbPercentage}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>SSLC PERCENTAGE</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {applyform.sslcPercentage}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>
                            DATE & TIME TO CALL
                          </th>
                          <td
                            style={{ fontWeight: "500", fontSize: "0.85em" }}
                            className="right"
                          >
                            {applyform.date}-{applyform.time}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ color: "darkblue" }}>Submitted On</th>
                          <td
                            style={{ fontWeight: "500", fontSize: "0.85em" }}
                            className="right"
                          >
                            {moment(applyform.createdAt.toDate()).format("LLL")}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CollapsibleItem>
              );
            })}
        </Collapsible>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    applyforms: state.firestore.ordered.applyForm,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: "applyForm", orderBy: ["createdAt", "desc"] },
  ])
)(ApplyForm);
