import React from 'react'
import {Redirect} from 'react-router-dom'


const Dummy=()=>{

   return(
    <Redirect to ='/whatsappform'/>
   )
}
export default Dummy