import React from "react";
import { Collapsible, CollapsibleItem, Icon, Table,Button } from "react-materialize";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose} from "redux";
import {Redirect} from 'react-router-dom';
import moment from 'moment';
import {askForPermissionToReceiveNotifications} from './config/pushnotification';

class WhatsappForm extends React.Component {
  render() {
    const { whatsappforms, auth,tokens } = this.props;
    
    let button;
   if(auth.uid&&tokens){
    const token=tokens.filter(token=>{
      return  token.uid===auth.uid
     })
     if(!token.length){
        button=<Button className="center shownotify" onClick={askForPermissionToReceiveNotifications}>Click To Show Notifications</Button>
     }
     else{
       button=null
     }
   }
    if (!auth.uid) return <Redirect to='/signin' /> 
    return (
      <div
        className="container contactform"
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          padding:'0px 0px 20px 0px',
          boxShadow: "0px 0px 5px lightblue",
        }}
      >
        {whatsappforms && (
          <p className="center submittedforms">
            Number of Whatsapp Forms Submitted-{whatsappforms.length}
          </p>
        )}
        <Collapsible accordion popout style={{ fontWeight: 600 }}>
          {whatsappforms &&
            whatsappforms.map((whatsappform) => {
              return (
                <CollapsibleItem
                  expanded={false}
                  header={whatsappform.whatsappNumber}
                  node="div"
                  icon={
                    <Icon right className="right">
                      arrow_drop_down_circle
                    </Icon>
                  }
                  key={whatsappform.id}
                >
                  <div className="container">
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ color: "darkblue" }}>Name</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {whatsappform.name}
                          </td>
                        </tr>
                      </thead>
                    </Table>
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ color: "darkblue" }}>CITY</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {whatsappform.city}
                          </td>
                        </tr>
                      </thead>
                    </Table>
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ color: "darkblue" }}>Submitted On</th>
                          <td style={{ fontWeight: "500" }} className="right">
                            {moment(whatsappform.createdAt.toDate()).format('LLL')}
                          </td>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </CollapsibleItem>
              );
            })}
        </Collapsible>
       {
         button
       }
        </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    whatsappforms: state.firestore.ordered.whatsappForm,
    tokens: state.firestore.ordered.tokens,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "whatsappForm",orderBy: ['createdAt', 'desc']}]),
  firestoreConnect([{ collection: "tokens" }]))(WhatsappForm);
