import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import '@firebase/messaging';



var fbconfig = {
    apiKey: "AIzaSyCgafCWHSW4HpIgevWset5lDPIOw24rKZQ",
    authDomain: "fexmeedu.firebaseapp.com",
    databaseURL: "https://fexmeedu.firebaseio.com",
    projectId: "fexmeedu",
    storageBucket: "fexmeedu.appspot.com",
    messagingSenderId: "327463834003",
    appId: "1:327463834003:web:3984eb541db7b00a4c9866",
    measurementId: "G-N9M1QBQZQD"
  };
  // Initialize Firebase
  firebase.initializeApp(fbconfig);
firebase.firestore()



  export default firebase;