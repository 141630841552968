import React from 'react';
import 'materialize-css';
import {Navbar,NavItem} from 'react-materialize';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import{connect} from 'react-redux';
import {compose} from 'redux'
import{ SignOutAction} from '../store/actions/authaction';
class NavBar extends React.Component{
  constructor(props){
    super(props)
    this.state={
    
    }
  }
  
  render(){
  if(this.props.auth.uid){return(
    <Navbar
 className="blue darken-3"
  fixed
  alignLinks="right"
  
  brand={<Link className="brand-logo" to="/whatsappform" >Fexme Educational Organisation</Link>}
  id="mobile-nav"
  menuIcon={<i style={{fontSize:'1.5em'}} className="fa fa-bars"></i>}
  options={{
    draggable: true,
    edge: 'left',
    inDuration: 250,
    outDuration: 200,
    preventScrolling: true
  }}
>
  <NavItem  onClick={()=>{this.props.history.push('/whatsappform')}}>
      WHATSAPP FORMS
  </NavItem>
  <NavItem  onClick={()=>{this.props.history.push('/contactform')}}>
      CONTACT FORMS
  </NavItem>
  <NavItem  onClick={()=>{this.props.history.push('/applyform')}}>
      APPLICATION FORMS
  </NavItem>
  <NavItem   onClick={this.props.signOut}>
      SIGN OUT
  </NavItem>
</Navbar>
  )}
  else {
    return(
      <Navbar
      className="blue darken-3"
       fixed
       alignLinks="right"
       
       brand={<Link className="brand-logo" to="/whatsappform" >Fexme Educational Organisation</Link>}
       id="mobile-nav"
       menuIcon={<i style={{fontSize:'1.5em'}} className="fa fa-bars"></i>}
       options={{
         draggable: true,
         edge: 'left',
         inDuration: 250,
         outDuration: 200,
         preventScrolling: true
       }}
     >
      <NavItem  onClick={()=>{this.props.history.push('/signin')}}>
      SIGN IN
    </NavItem>
    </Navbar>
    )
  }
}
}
const mapStateToProps=(state)=>{
  return{
    auth:state.firebase.auth,
  }
}
const mapDispatchToProps=(dispatch)=>{
  return{
  signOut:()=>dispatch(SignOutAction())
  }
}
export default compose(connect(mapStateToProps,mapDispatchToProps),withRouter)(NavBar)