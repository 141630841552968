export const signInAction=(credentials)=>{
    return(dispatch,getState,{getFirebase})=>{
        const firebase=getFirebase();
        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(()=>{
            dispatch({type:'LOGIN_SUCCESS'})
        }).catch(err=>{
            dispatch({type:'LOGIN_ERROR',err})
        })
        }
}

export const SignOutAction=()=>{
    return(dispatch,getState,{getFirebase})=>   {
        const firebase=getFirebase()
        firebase.auth().signOut().then(function() {
             dispatch({type:'SIGNOUT_SUCCESS'})
            }
          ).catch(function() {
           dispatch({type:'SIGNOUT_FAILED'})
          });
    }
}
