import firebase from './fbconfig';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyCgafCWHSW4HpIgevWset5lDPIOw24rKZQ",
    authDomain: "fexmeedu.firebaseapp.com",
    databaseURL: "https://fexmeedu.firebaseio.com",
    projectId: "fexmeedu",
    storageBucket: "fexmeedu.appspot.com",
    messagingSenderId: "327463834003",
    appId: "1:327463834003:web:3984eb541db7b00a4c9866",
    measurementId: "G-N9M1QBQZQD"
  });
}
 const db=firebase.firestore();
  const messaging=firebase.messaging()
const auth=firebase.auth();

messaging.onTokenRefresh(handleTokenRefresh);

export const askForPermissionToReceiveNotifications = () => {
    messaging.requestPermission()
    .then(() => handleTokenRefresh())
    .catch((err) => {
      console.log("error getting permission:",err);
    });
}

function handleTokenRefresh() {
    return messaging.getToken().then((token) => {
     db.collection('tokens').doc(auth.currentUser.uid).set({
        token: token,
        uid: auth.currentUser.uid
      });
    });
  }

