import React from 'react';
import NavBar from './components/navbar';
import { BrowserRouter,Switch,Route} from "react-router-dom";
import SignIn from './components/signin';
import WhatsappForm from './components/whatsappform';
import ContactForm from './components/contactform';
import ApplyForm from './components/applyform';
import Dummy from './components/dummy';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <NavBar />
        <Switch>
          <Route exact path="/" component={Dummy}/>
          <Route exact path="/signin" component={SignIn}/>
          <Route exact path="/whatsappform" component={WhatsappForm}/>
          <Route exact path="/contactform" component={ContactForm}/>
          <Route exact path="/applyform" component={ApplyForm}/>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
