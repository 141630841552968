import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createStore, applyMiddleware,compose} from 'redux';
import rootReducer from './store/reducers/rootreducer';
import {Provider,useSelector} from 'react-redux';
import thunk from 'redux-thunk'
import {createFirestoreInstance, reduxFirestore, getFirestore } from 'redux-firestore';
import { ReactReduxFirebaseProvider, getFirebase,isLoaded } from 'react-redux-firebase';
import fbconfig from './components/config/fbconfig';
import firebase from 'firebase/app';
const store=createStore(rootReducer, 
  compose(
    applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})),// redux binding for firebase
    reduxFirestore(firebase,fbconfig) // redux bindings for firestore
  )
  
  );
  
  function AuthIsLoaded({children}){
    const auth=useSelector(state=>state.firebase.auth)
    if(!isLoaded(auth)) return <div></div>;
    return children
  }

  const rrfConfig={
    userProfile:'users',
    useFirestoreForProfile:true
  }
  const rrfProps={
    firebase,
    config:rrfConfig,fbconfig,
    dispatch:store.dispatch,
    createFirestoreInstance
  }

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider
    {...rrfProps}>
    <AuthIsLoaded><App/></AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
